<template>
  <section class="my-16">
    <v-container>
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="6"
          class="text-center order-1"
          :class="{
            'order-sm-0': section.leftImage,
            'order-sm-1': !section.leftImage,
          }"
        >
          <div class="d-flex justify-center align-center full-parent">
            <vue-aos
              animation-class="animate__animated"
              :class="{
                animate__zoomInLeft: section.leftImage,
                animate__zoomInRight: !section.leftImage,
              }"
            >
              <v-img contain max-height="300" :src="section.imageSrc"></v-img>
            </vue-aos>
          </div>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          class="pa-8 grey--text text--darken-1 order-0"
          :class="{
            'order-sm-1': section.leftImage,
            'order-sm-0': !section.leftImage,
          }"
        >
          <div class="d-flex justify-center align-center">
            <div>
              <vue-aos
                animation-class="animate__animated"
                :class="{
                  animate__zoomInRight: section.leftImage,
                  animate__zoomInLeft: !section.leftImage,
                }"
              >
                <h2
                  class="text-h4 mb-8 black--text"
                  v-text="section.title"
                ></h2>
              </vue-aos>
              <vue-aos
                animation-class="animate__animated"
                :class="{
                  animate__zoomInRight: section.leftImage,
                  animate__zoomInLeft: !section.leftImage,
                }"
              >
                <div v-html="$sanitize(section.content)"></div>
              </vue-aos>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import VueAos from "vue-aos";

export function Section(
  options = {
    menuTitle: "Menu",
    imageSrc: "/img/brasao_256.png",
    title: "Título",
    content: `Conteúdo`,
    leftImage: true,
  }
) {
  Object.defineProperty(this, "type", { get: () => "regular" });

  this.menuTitle = options.menu || options.menuTitle;
  this.imageSrc = options.enderecoDaImagem || options.imageSrc;
  this.title = options.titulo || options.title;
  this.content = options.conteudo || options.content;
  this.leftImage =
    typeof options.imagemNoLadoEsquerdo !== "undefined"
      ? options.imagemNoLadoEsquerdo
      : options.leftImage;

  this.isValid = function () {
    return this.imageSrc || this.title || this.content;
  };

  this.stringify = function () {
    return JSON.stringify({ ...this, type: "regular" });
  };

  this.toApi = function () {
    return {
      menu: this.menuTitle,
      enderecoDaImagem: this.imageSrc,
      titulo: this.title,
      conteudo: this.content,
      imagemNoLadoEsquerdo: this.leftImage,
      tipo: "regular",
    };
  };

  if (!this.isValid()) {
    throw new Error("Seção de conteúdo inválida.");
  }
}

export default {
  name: "LandingPageSection",
  components: { VueAos },
  props: {
    preview: {
      type: Boolean,
      required: false,
    },
    section: {
      type: Section,
      required: true,
      validator(value) {
        return value.isValid();
      },
    },
  },
};
</script>