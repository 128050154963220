var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "landing-page-cover"
  }, [_c('v-parallax', {
    class: {
      dark: _vm.section.dark,
      'default-height': !_vm.hasCustomHeight
    },
    attrs: {
      "src": _vm.section.coverSrc,
      "height": _vm.parallaxHeight
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_vm.section.logoSrc ? _c('v-img', {
    staticClass: "mb-10",
    attrs: {
      "contain": "",
      "max-height": "256",
      "src": _vm.section.logoSrc,
      "alt": "Logotipo do Programa"
    }
  }) : _vm._e(), _vm.section.title ? _c('h1', {
    staticClass: "display-2 font-weight-black mb-12",
    domProps: {
      "innerHTML": _vm._s(_vm.$sanitize(_vm.section.title))
    }
  }) : _vm._e(), _vm.section.secondText ? _c('div', {
    staticClass: "subheading font-weight-light mb-3",
    domProps: {
      "innerHTML": _vm._s(_vm.$sanitize(_vm.section.secondText)),
      "textContent": _vm._s(_vm.section.secondText)
    }
  }) : _vm._e(), _vm.section.thirdText ? _c('div', {
    staticClass: "display-1 font-weight-thin mb-12",
    domProps: {
      "innerHTML": _vm._s(_vm.$sanitize(_vm.section.thirdText))
    }
  }) : _vm._e(), _vm.section.loginButton.title ? _c('v-btn', {
    staticClass: "pa-6 font-weight-regular",
    attrs: {
      "rounded": "",
      "color": "success",
      "to": {
        path: _vm.preview ? '' : _vm.section.loginButton.path
      }
    },
    domProps: {
      "textContent": _vm._s(_vm.section.loginButton.title)
    }
  }) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }