var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "my-16"
  }, [_c('v-container', [_c('vue-aos', {
    attrs: {
      "animation-class": "animate__zoomInLeft animate__animated"
    }
  }, [_c('v-row', {
    staticClass: "mb-15",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "text-center"
  }, [_c('h2', {
    staticClass: "text-h4",
    domProps: {
      "textContent": _vm._s(_vm.section.title)
    }
  })])], 1)], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, _vm._l(_vm.section.features, function (feature, index) {
    return _c('vue-aos', {
      key: `feature-${index}`,
      attrs: {
        "animation-class": `animate__zoomIn${index % 2 === 0 ? 'Left' : 'Right'} animate__animated`
      }
    }, [_c('v-col', {
      staticClass: "pa-4 grey--text text--darken-1",
      attrs: {
        "cols": "10",
        "sm": "5",
        "offset": "1"
      }
    }, [_c('div', {
      staticClass: "d-flex justify-start align-center"
    }, [_c('div', [_c('h4', {
      staticClass: "text-h6 black--text mb-2"
    }, [_c('v-icon', {
      attrs: {
        "color": "blue darken-2"
      }
    }, [_vm._v("mdi-check")]), _c('span', {
      domProps: {
        "textContent": _vm._s(feature.title)
      }
    })], 1), _c('p', {
      domProps: {
        "innerHTML": _vm._s(_vm.$sanitize(feature.description))
      }
    })])])])], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }