<template>
  <section class="my-16">
    <v-container>
      <vue-aos animation-class="animate__zoomInLeft animate__animated">
        <v-row no-gutters class="mb-15">
          <v-col class="text-center">
            <h2 class="text-h4" v-text="section.title"></h2>
          </v-col>
        </v-row>
      </vue-aos>

      <v-row no-gutters>
        <vue-aos
          v-for="(feature, index) in section.features"
          :key="`feature-${index}`"
          :animation-class="`animate__zoomIn${
            index % 2 === 0 ? 'Left' : 'Right'
          } animate__animated`"
        >
          <v-col
            cols="10"
            sm="5"
            offset="1"
            class="pa-4 grey--text text--darken-1"
          >
            <div class="d-flex justify-start align-center">
              <div>
                <h4 class="text-h6 black--text mb-2">
                  <v-icon color="blue darken-2">mdi-check</v-icon>
                  <span v-text="feature.title"></span>
                </h4>
                <p v-html="$sanitize(feature.description)"></p>
              </div>
            </div>
          </v-col>
        </vue-aos>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import VueAos from "vue-aos";

export function FeatureItem(
  options = { title: undefined, description: undefined }
) {
  this.title = options.titulo || options.title;
  this.description = options.descricao || options.description;

  this.isValid = function () {
    return this.title;
  };

  this.stringify = function () {
    return JSON.stringify({ ...this });
  };

  this.toApi = function () {
    return {
      titulo: this.title,
      descricao: this.description,
    };
  };

  if (!this.isValid()) {
    throw new Error("Recurso inválido.");
  }
}

export function Features(
  options = { features: [], menuTitle: "Detalhes", title: "Detalhes" }
) {
  Object.defineProperty(this, "type", { get: () => "features" });

  this.menuTitle = options.menu || options.menuTitle;
  this.title = options.titulo || options.title;
  this.features = (options.recursos || options.features || []).map(
    (feature) => new FeatureItem(feature)
  );

  this.isValid = function () {
    return this.features.length
      ? this.features.every((feature) => feature.isValid())
      : true;
  };

  this.stringify = function () {
    return JSON.stringify({ ...this, type: "features" });
  };

  this.toApi = function () {
    return {
      menu: this.menuTitle,
      titulo: this.title,
      recursos: this.features.map((feature) => feature.toApi()),
      tipo: "recursos",
    };
  };

  if (!this.isValid()) {
    throw new Error("Seção de Recursos inválida.");
  }
}

export default {
  name: "LandingPageFeatures",
  components: { VueAos },
  props: {
    section: {
      type: Features,
      required: true,
      validator(value) {
        return value.isValid();
      },
    },
  },
};
</script>