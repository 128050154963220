var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "my-10",
    attrs: {
      "id": "section4"
    }
  }, [_c('v-container', [_c('v-row', {
    staticClass: "mb-5",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('vue-aos', {
    attrs: {
      "animation-class": "animate__zoomInLeft animate__animated"
    }
  }, [_c('v-col', {
    staticClass: "text-center"
  }, [_c('h2', {
    staticClass: "text-h4",
    domProps: {
      "textContent": _vm._s(_vm.section.title)
    }
  })])], 1)], 1), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "faq"
  }, [_c('v-text-field', {
    attrs: {
      "solo": "",
      "rounded": "",
      "clearable": "",
      "loading": _vm.isSearching,
      "placeholder": "Faça uma pergunta",
      "prepend-inner-icon": "mdi-magnify",
      "autocomplete": "off"
    },
    on: {
      "click:clear": _vm.clear,
      "input": _vm.search
    },
    model: {
      value: _vm.textToSearch,
      callback: function ($$v) {
        _vm.textToSearch = $$v;
      },
      expression: "textToSearch"
    }
  }), _c('v-expansion-panels', {
    attrs: {
      "focusable": ""
    },
    model: {
      value: _vm.panel,
      callback: function ($$v) {
        _vm.panel = $$v;
      },
      expression: "panel"
    }
  }, _vm._l(_vm.visiblePage, function (item, i) {
    return _c('v-expansion-panel', {
      key: i
    }, [_c('v-expansion-panel-header', [_c('strong', {
      staticClass: "text--darken-2 grey--text",
      domProps: {
        "textContent": _vm._s(item.title)
      }
    })]), _c('v-expansion-panel-content', [_c('div', {
      staticClass: "mt-3",
      domProps: {
        "innerHTML": _vm._s(item.description)
      }
    })])], 1);
  }), 1), _vm.searchedItems.length && _vm.paginationLength > 1 ? _c('div', {
    staticClass: "text-center mt-5"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.paginationLength,
      "circle": ""
    },
    on: {
      "input": function ($event) {
        _vm.panel = [];
      }
    },
    model: {
      value: _vm.pagination.page,
      callback: function ($$v) {
        _vm.$set(_vm.pagination, "page", $$v);
      },
      expression: "pagination.page"
    }
  })], 1) : _vm._e(), _vm.textToSearch && !_vm.isSearching && !_vm.searchedItems.length ? _c('div', {
    staticClass: "text-center text--darken-1 grey--text font-weight-light"
  }, [_vm._v(" Infelizmente não encontramos nenhuma resposta... "), _c('v-icon', [_vm._v("mdi-emoticon-sad-outline")])], 1) : _vm._e()], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }