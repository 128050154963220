var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "page-container pa-0",
    class: {
      preview: _vm.preview
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('v-app-bar', {
    directives: [{
      name: "scroll",
      rawName: "v-scroll",
      value: _vm.onScroll,
      expression: "onScroll"
    }],
    staticClass: "topbar",
    class: {
      'topbar--visible': _vm.showTopbarColor,
      'elevation-2': _vm.showTopbarColor
    },
    attrs: {
      "fixed": !_vm.preview,
      "flat": "",
      "dark": "",
      "color": "transparent"
    }
  }, [_c('v-btn', {
    attrs: {
      "plain": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$vuetify.goTo(0, {
          duration: 1000,
          offset: 15
        });
      }
    }
  }, [_c('img', {
    staticClass: "btn-logo-img",
    attrs: {
      "alt": "Brasão de Pernambuco",
      "src": "/img/brasao_64.png"
    }
  })]), _c('v-app-bar-title', {
    staticClass: "d-sm-none font-weight-bold",
    domProps: {
      "textContent": _vm._s(_vm.$configurations.app.alias)
    }
  }), _c('v-spacer'), _vm._l(_vm.content.sections, function (section, index) {
    return [section.menuTitle ? _c('v-btn', {
      key: `menu-${index}`,
      staticClass: "d-none d-sm-flex",
      attrs: {
        "preview": _vm.preview,
        "plain": "",
        "tile": ""
      },
      domProps: {
        "textContent": _vm._s(section.menuTitle)
      },
      on: {
        "click": function ($event) {
          return _vm.$vuetify.goTo(`#section-${index}`, {
            duration: 1000,
            offset: 15
          });
        }
      }
    }) : _vm._e()];
  }), _c('v-spacer')], 2), _vm._l(_vm.content.sections, function (section, index) {
    return [_vm.sectionComponent(section) ? _c(_vm.sectionComponent(section), {
      key: `section-${index}`,
      tag: "component",
      attrs: {
        "id": `section-${index}`,
        "preview": _vm.preview,
        "section": section
      }
    }) : _vm._e()];
  }), _c('lp-social', {
    attrs: {
      "preview": _vm.preview
    }
  }), _c('lp-footer', {
    attrs: {
      "preview": _vm.preview
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }