<template>
  <section class="my-4">
    <v-row no-gutters>
      <v-col class="text-center px-0">
        <v-parallax :src="section.imageSrc" :height="300" class="dark">
          <v-row class="d-flex justify-space-between align-center">
            <vue-aos animation-class="animate__zoomInLeft animate__animated">
              <v-col>
                <div
                  class="text-h4 font-weight-bold"
                  v-text="section.left.value"
                ></div>
                <small
                  class="font-weight-light"
                  v-text="section.left.description"
                ></small>
              </v-col>
            </vue-aos>
            <vue-aos animation-class="animate__zoomInDown animate__animated">
              <v-col>
                <div
                  class="text-h4 font-weight-bold"
                  v-text="section.middle.value"
                ></div>
                <small
                  class="font-weight-light"
                  v-text="section.middle.description"
                ></small>
              </v-col>
            </vue-aos>
            <vue-aos animation-class="animate__zoomInRight animate__animated">
              <v-col>
                <div
                  class="text-h4 font-weight-bold"
                  v-text="section.right.value"
                ></div>
                <small
                  class="font-weight-light"
                  v-text="section.right.description"
                ></small>
              </v-col>
            </vue-aos>
          </v-row>
        </v-parallax>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import VueAos from "vue-aos";

export function IndicatorItem(
  options = { value: "0", description: undefined }
) {
  this.value = options.valor || options.value;
  this.description = options.descricao || options.description;

  this.isValid = function () {
    return typeof this.value !== "undefined";
  };

  this.stringify = function () {
    return JSON.stringify({ ...this });
  };

  this.toApi = function () {
    return {
      valor: this.value,
      description: this.description,
    };
  };

  if (!this.isValid()) {
    throw new Error("Indicador inválido.");
  }
}

export function Indicators(
  options = { menuTitle: "Indicadores", imageSrc: "/img/bg-09.jpg" }
) {
  Object.defineProperty(this, "type", { get: () => "indicators" });

  this.menuTitle = options.menu || options.menuTitle;
  this.imageSrc = options.enderecoDaImagem || options.imageSrc;
  this.left =
    options.indicadorEsquerdo || options.left
      ? new IndicatorItem(options.indicadorEsquerdo || options.left)
      : new IndicatorItem();
  this.middle =
    options.indicadorDoMeio || options.middle
      ? new IndicatorItem(options.indicadorDoMeio || options.middle)
      : new IndicatorItem();
  this.right =
    options.indicadorDireito || options.right
      ? new IndicatorItem(options.indicadorDireito || options.right)
      : new IndicatorItem();

  this.isValid = function () {
    return (
      this.imageSrc &&
      this.left instanceof IndicatorItem &&
      this.left.isValid() &&
      this.middle instanceof IndicatorItem &&
      this.middle.isValid() &&
      this.right instanceof IndicatorItem &&
      this.right.isValid()
    );
  };

  this.stringify = function () {
    return JSON.stringify({ ...this, type: "indicators" });
  };

  this.toApi = function () {
    return {
      menu: this.menuTitle,
      enderecoDaImagem: this.imageSrc,
      indicadorEsquerdo: this.left.toApi(),
      indicadorDoMeio: this.middle.toApi(),
      indicadorDireito: this.right.toApi(),
      tipo: "indicadores",
    };
  };

  if (!this.isValid()) {
    throw new Error("Seção de Indicadores inválida.");
  }
}

export default {
  name: "LandingPageIndicators",
  components: { VueAos },
  props: {
    preview: {
      type: Boolean,
      required: false,
    },
    section: {
      type: Indicators,
      required: true,
      validator(value) {
        return value.isValid();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.v-parallax) {
  position: relative;
  max-width: 100vw;

  &.dark {
    .v-parallax__image-container {
      filter: brightness(50%);
    }
  }
}
</style>