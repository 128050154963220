var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "my-4"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "text-center px-0"
  }, [_c('v-parallax', {
    staticClass: "dark",
    attrs: {
      "src": _vm.section.imageSrc,
      "height": 300
    }
  }, [_c('v-row', {
    staticClass: "d-flex justify-space-between align-center"
  }, [_c('vue-aos', {
    attrs: {
      "animation-class": "animate__zoomInLeft animate__animated"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "text-h4 font-weight-bold",
    domProps: {
      "textContent": _vm._s(_vm.section.left.value)
    }
  }), _c('small', {
    staticClass: "font-weight-light",
    domProps: {
      "textContent": _vm._s(_vm.section.left.description)
    }
  })])], 1), _c('vue-aos', {
    attrs: {
      "animation-class": "animate__zoomInDown animate__animated"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "text-h4 font-weight-bold",
    domProps: {
      "textContent": _vm._s(_vm.section.middle.value)
    }
  }), _c('small', {
    staticClass: "font-weight-light",
    domProps: {
      "textContent": _vm._s(_vm.section.middle.description)
    }
  })])], 1), _c('vue-aos', {
    attrs: {
      "animation-class": "animate__zoomInRight animate__animated"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "text-h4 font-weight-bold",
    domProps: {
      "textContent": _vm._s(_vm.section.right.value)
    }
  }), _c('small', {
    staticClass: "font-weight-light",
    domProps: {
      "textContent": _vm._s(_vm.section.right.description)
    }
  })])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }