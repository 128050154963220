<template>
  <section id="section4" class="my-10">
    <v-container>
      <v-row no-gutters class="mb-5">
        <vue-aos animation-class="animate__zoomInLeft animate__animated">
          <v-col class="text-center">
            <h2 class="text-h4" v-text="section.title"></h2>
          </v-col>
        </vue-aos>
      </v-row>

      <v-row>
        <v-col>
          <div class="faq">
            <v-text-field
              v-model="textToSearch"
              solo
              rounded
              clearable
              :loading="isSearching"
              placeholder="Faça uma pergunta"
              prepend-inner-icon="mdi-magnify"
              @click:clear="clear"
              @input="search"
              autocomplete="off"
            ></v-text-field>

            <v-expansion-panels focusable v-model="panel">
              <v-expansion-panel v-for="(item, i) in visiblePage" :key="i">
                <v-expansion-panel-header>
                  <strong
                    v-text="item.title"
                    class="text--darken-2 grey--text"
                  ></strong>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="mt-3" v-html="item.description"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <div
              v-if="searchedItems.length && paginationLength > 1"
              class="text-center mt-5"
            >
              <v-pagination
                v-model="pagination.page"
                :length="paginationLength"
                @input="panel = []"
                circle
              ></v-pagination>
            </div>

            <div
              class="text-center text--darken-1 grey--text font-weight-light"
              v-if="textToSearch && !isSearching && !searchedItems.length"
            >
              Infelizmente não encontramos nenhuma resposta...
              <v-icon>mdi-emoticon-sad-outline</v-icon>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
/* eslint-disable no-prototype-builtins */
import VueAos from "vue-aos";
import Lunr from "lunr";
import lunrStemmerSupport from "lunr-languages/lunr.stemmer.support";
import lunrLangPt from "lunr-languages/lunr.pt";

export function FaqItem(
  options = {
    id: undefined,
    title: undefined,
    description: undefined,
    tokens: [],
  }
) {
  this.id = options.id;
  this.title = options.title;
  this.description = options.description;
  this.tokens = options.tokens || [];

  this.isValid = function () {
    return (
      this.id && this.title && this.description && Array.isArray(this.tokens)
    );
  };

  if (!this.isValid()) {
    throw new Error("Item de FAQ inválido.");
  }
}

export function Faq(
  options = { menuTitle: "FAQ", title: "Perguntas Frequentes", items: [] }
) {
  Object.defineProperty(this, "type", { get: () => "faq" });

  this.menuTitle = options.menu || options.menuTitle;
  this.title = options.titulo || options.title;
  this.items =
    options.items && Array.isArray(options.items)
      ? options.items.map((item) => new FaqItem(item))
      : [];

  this.isValid = function () {
    if (!Array.isArray(this.items)) {
      return false;
    }

    if (this.items.length > 0) {
      return !this.items.some((item) => {
        return !(item instanceof FaqItem) || !item.isValid();
      });
    }

    return true;
  };

  this.stringify = function () {
    return JSON.stringify({ ...this, type: "faq" });
  };

  this.toApi = function () {
    return {
      menu: this.menuTitle,
      titulo: this.title,
      tipo: "faq",
    };
  };

  if (!this.isValid()) {
    throw new Error("Seção de FAQ inválida.");
  }
}

export default {
  name: "LandingPageFaq",
  components: { VueAos },
  props: {
    preview: {
      type: Boolean,
      required: false,
    },
    section: {
      type: Faq,
      required: true,
      validator(value) {
        return value.isValid();
      },
    },
  },
  data: () => ({
    textToSearch: undefined,
    isSearching: false,
    interval: undefined,
    searchEngine: undefined,
    searchEngine1: undefined,
    panel: [],
    searchedItems: [],
    pagination: {
      page: 1,
      size: 5,
    },
  }),
  computed: {
    visiblePage() {
      return this.searchedItems.slice(
        (this.pagination.page - 1) * this.pagination.size,
        this.pagination.page * this.pagination.size
      );
    },
    paginationLength() {
      return Math.ceil(this.searchedItems.length / this.pagination.size);
    },
  },
  watch: {
    section() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.prepareSearchEngine();
      this.searchedItems = this.section.items;
    },
    clear() {
      this.searchedItems = this.section.items;
      this.panel = [];
    },
    getNormalizedText(text) {
      return (text || "")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
    },
    prepareSearchEngine() {
      if (typeof Lunr.pt === "undefined") {
        lunrStemmerSupport(Lunr);
        lunrLangPt(Lunr);
      }

      const self = this;
      const engine = Lunr(function () {
        this.use(Lunr.pt);
        this.ref("id");
        this.field("title");
        this.field("description");
        this.field("tokens");
        for (let item of self.section.items) {
          this.add({
            id: item.id,
            title: self.getNormalizedText(item.title),
            description: self.getNormalizedText(item.description),
            tokens: self.getNormalizedText((item.tokens || []).join(" ")),
          });
        }
      });

      this.searchEngine = Object.freeze(engine);
    },
    search() {
      const minCharacters = 3;
      const searchDelay = 500;
      const searchedText = this.getNormalizedText(this.textToSearch || "");

      this.isSearching = false;

      clearTimeout(this.interval);

      if (searchedText.length >= minCharacters) {
        this.isSearching = true;
        this.interval = setTimeout(() => {
          const searchedIds = (
            searchedText.length >= minCharacters
              ? this.searchEngine.search(searchedText) || []
              : []
          ).map((s) => parseInt(s.ref));
          this.searchedItems = this.section.items.filter(
            (item) => searchedIds.indexOf(item.id) >= 0
          );
          this.panel = [];
          this.isSearching = false;
        }, searchDelay);
      } else {
        this.searchedItems = this.section.items;
      }
    },
  },
};
</script>