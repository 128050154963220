var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "my-16"
  }, [_c('v-container', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "text-center order-1",
    class: {
      'order-sm-0': _vm.section.leftImage,
      'order-sm-1': !_vm.section.leftImage
    },
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center full-parent"
  }, [_c('vue-aos', {
    class: {
      animate__zoomInLeft: _vm.section.leftImage,
      animate__zoomInRight: !_vm.section.leftImage
    },
    attrs: {
      "animation-class": "animate__animated"
    }
  }, [_c('v-img', {
    attrs: {
      "contain": "",
      "max-height": "300",
      "src": _vm.section.imageSrc
    }
  })], 1)], 1)]), _c('v-col', {
    staticClass: "pa-8 grey--text text--darken-1 order-0",
    class: {
      'order-sm-1': _vm.section.leftImage,
      'order-sm-0': !_vm.section.leftImage
    },
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('div', [_c('vue-aos', {
    class: {
      animate__zoomInRight: _vm.section.leftImage,
      animate__zoomInLeft: !_vm.section.leftImage
    },
    attrs: {
      "animation-class": "animate__animated"
    }
  }, [_c('h2', {
    staticClass: "text-h4 mb-8 black--text",
    domProps: {
      "textContent": _vm._s(_vm.section.title)
    }
  })]), _c('vue-aos', {
    class: {
      animate__zoomInRight: _vm.section.leftImage,
      animate__zoomInLeft: !_vm.section.leftImage
    },
    attrs: {
      "animation-class": "animate__animated"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.$sanitize(_vm.section.content))
    }
  })])], 1)])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }