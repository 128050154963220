<template>
  <v-container fluid class="page-container pa-0" :class="{ preview: preview }">
    <v-app-bar
      :fixed="!preview"
      flat
      dark
      color="transparent"
      class="topbar"
      :class="{
        'topbar--visible': showTopbarColor,
        'elevation-2': showTopbarColor,
      }"
      v-scroll="onScroll"
    >
      <v-btn plain @click="$vuetify.goTo(0, { duration: 1000, offset: 15 })">
        <img
          class="btn-logo-img"
          alt="Brasão de Pernambuco"
          src="/img/brasao_64.png"
        />
      </v-btn>

      <v-app-bar-title
        v-text="$configurations.app.alias"
        class="d-sm-none font-weight-bold"
      ></v-app-bar-title>

      <v-spacer></v-spacer>

      <template v-for="(section, index) in content.sections">
        <v-btn
          v-if="section.menuTitle"
          :key="`menu-${index}`"
          :preview="preview"
          class="d-none d-sm-flex"
          plain
          tile
          @click="
            $vuetify.goTo(`#section-${index}`, { duration: 1000, offset: 15 })
          "
          v-text="section.menuTitle"
        ></v-btn>
      </template>

      <v-spacer></v-spacer>
    </v-app-bar>

    <template v-for="(section, index) in content.sections">
      <component
        v-if="sectionComponent(section)"
        :is="sectionComponent(section)"
        :id="`section-${index}`"
        :key="`section-${index}`"
        :preview="preview"
        :section="section"
      ></component>
    </template>

    <lp-social :preview="preview"></lp-social>

    <lp-footer :preview="preview"></lp-footer>
  </v-container>
</template>

<script>
import { Cover, default as LpCover } from "components/landing-page/LandingPageCover";
import { Indicators, default as LpIndicators } from "components/landing-page/LandingPageIndicators";
import { Features, default as LpFeatures } from "components/landing-page/LandingPageFeatures";
import { Section, default as LpSection } from "components/landing-page/LandingPageSection";
import { Faq, FaqItem, default as LpFaq } from "components/landing-page/LandingPageFaq";

import LpSocial from "components/landing-page/LandingPageSocial";
import LpFooter from "components/landing-page/LandingPageFooter";

import { LOGOUT, AUTH_NAMESPACE } from "store/modules/auth";
import { createNamespacedHelpers } from "vuex";

const {
  mapActions: preLoginActions,
  // mapGetters: preLoginGetters,
} = createNamespacedHelpers(AUTH_NAMESPACE);

export default {
  name: "LandingPage",
  components: {
    LpCover,
    LpSection,
    LpIndicators,
    LpFeatures,
    LpFaq,
    LpSocial,
    LpFooter,
  },
  props: {
    preview: {
      type: Boolean,
      required: false,
    },
    previewData: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    content: {
      sections: [],
    },
    showTopbarColor: false,
  }),
  mounted() {
    if (!this.preview) {
      this.loadContent().then(() => {
        const faqList = this.content.sections.filter(
          (section) => section.type === "faq"
        );

        if (Array.isArray(faqList) && faqList.length > 0) {
          this.fetchFaqItems().then((items) => {
            // Recria as seções
            const sections = this.content.sections.map((section) => {
              // recria as seções de FAQ com os itens
              if (section.type === "faq") {
                return new Faq({
                  menuTitle: section.menuTitle,
                  title: section.title,
                  items: items,
                }); // novo objeto para provocar reatividade
              }

              return section;
            });

            // Atualiza a lista de seções com as FAQ atualizadas
            this.content.sections = sections;
          });
        }
      });
    } else {
      this.app = this.previewData.app;
      this.content.sections = this.previewData.sections;
      this.showTopbarColor = this.previewData.showTopbarColor;
    }
  },
  methods: {
    ...preLoginActions([
      LOGOUT
    ]),
    sectionComponent(section) {
      const components = {
        cover: "LpCover",
        faq: "LpFaq",
        features: "LpFeatures",
        indicators: "LpIndicators",
        regular: "LpSection",
      };

      if (section) {
        return components[section.type];
      }

      return undefined;
    },
    onScroll() {
      const offset = 150;

      if (this.preview) {
        return;
      }

      if (window.pageYOffset > offset) {
        this.showTopbarColor = true;
      } else {
        this.showTopbarColor = false;
      }
    },
    loadContent() {
      return this.$obterConteudoSecoes().then(({ data }) => {
        data.secoes
          .map((section) => {
            switch (section.tipo) {
              case "capa":
                return new Cover(section);
              case "faq":
                return new Faq(section);
              case "recursos":
                return new Features(section);
              case "indicadores":
                return new Indicators(section);
              default:
                return new Section(section);
            }
          })
          .forEach((section) => {
            this.content.sections.push(section);
          });
      });
    },
    async fetchFaqItems() {
      return this.$obterPerguntasFaq()
        .then(({ data }) => {
          return data.faq.map((item) => {
            return new FaqItem({
              id: item.id,
              title: item.pergunta,
              description: item.resposta,
              tokens: item.palavrasChave,
            });
          });
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.$notifyWarning(response.data.mensagem);
          } else {
            this.$notifyWarning(
              "Não conseguimos consultar as perguntas e respostas do FAQ."
            );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
$negativeTopbarHeight: -64px;

.topbar {
  transition: all 0.75s;
}

.topbar--visible {
  background-color: #0d47a1 !important;
  border-color: #0d47a1 !important;
}

.page-container {
  .btn-logo-img {
    max-height: 36px;
  }

  &:not(.preview) {
    .cover {
      margin-top: $negativeTopbarHeight;
    }
  }
}
</style>